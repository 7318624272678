<script setup lang="ts">
import {
  Ibar,
  Footer,
  IJournal,
  ILinksBlock,
  Newsletter
} from "@frontiers-vue3/public-pages";
import { useMain } from "~/stores/main";
import { prependBaseUrlIfRelativeUrl } from "~/helpers";
import { ICategoryGroup } from "~/types/page-service";
import { mapCategoryGroupToLinkBlock } from "~/mappers/models";

const store = useMain();

const journalScience: IJournal = {
  id: 901191,
  name: "Science news",
  slug: "science-news",
  url: prependBaseUrlIfRelativeUrl("/news")
};

const journalLinks = [
  {
    text: "Research news",
    url: prependBaseUrlIfRelativeUrl("/news/category/research-news")
  },
  {
    text: "Frontiers updates",
    url: prependBaseUrlIfRelativeUrl("/news/category/frontiers-updates")
  },
  {
    text: "Open science",
    url: prependBaseUrlIfRelativeUrl("/news/category/open-science")
  },
  {
    text: "Press assets",
    url: "https://pressoffice.frontiersin.org/download-assets",
    target: "_blank"
  }
];

const aboutJournal = computed((): Array<ILinksBlock> => {
  return store.categoryGroupsLevel1.map((group: ICategoryGroup) =>
    mapCategoryGroupToLinkBlock(group)
  );
});

await Promise.all([
  store.fetchLandingPage(),
  store.fetchCategories(),
  store.fetchFooter(),
  store.fetchIbar()
]);
</script>

<template>
  <h1 class="acc-hidden">Frontiers | Science News</h1>
  <Ibar
    v-bind="store.ibar"
    about-journal-text="All categories"
    :journal="journalScience"
    :journal-links="journalLinks"
    :about-journal="aboutJournal" />
  <NuxtPage />

  <Newsletter
    title="Get the latest research updates, subscribe to our newsletter"
    subscription-type="sf_newsletter"
    button-text="Subscribe" />
  <Footer v-bind="store.footer" />
</template>
