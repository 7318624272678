// https://stackoverflow.com/questions/10687099/how-to-test-if-a-url-string-is-absolute-or-relative
const regExpIsAbsoluteUrl = new RegExp("^(?:[a-z+]+:)?//", "i");

function isAbsoluteUrl(url: string) {
  return regExpIsAbsoluteUrl.test(url);
}

function prependBaseUrlIfRelativeUrl(url: string) {
  const runtimeConfig = useRuntimeConfig();
  const isDev = process.env.NODE_ENV === "development";
  const baseUrl = isDev
    ? "http://localhost:3000"
    : runtimeConfig.public.baseUrl;

  if (!isAbsoluteUrl(url)) {
    return `${baseUrl}${url}`;
  }

  return url;
}

export { isAbsoluteUrl, prependBaseUrlIfRelativeUrl };
