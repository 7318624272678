import { ILinksBlock } from "@frontiers-vue3/public-pages";
import { ICategoryGroup } from "~/types/page-service";

const mapCategoryGroupToLinkBlock = (cms: ICategoryGroup): ILinksBlock => {
  return {
    title: {
      text: cms.name,
      url: `/news/category/${cms.slug}`
    },
    links: cms.items.map((g) => {
      return {
        text: g.name,
        url: `/news/category/${g.slug}`
      };
    })
  };
};

export { mapCategoryGroupToLinkBlock };
